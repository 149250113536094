<template>
  <div class="DiscountBar">

    <!-- Discount Form -->
    <form @submit.prevent="saveDiscount">
      <va-card>

        <h1 class="display--1 text--capitalize">Create new Discount</h1>

        <!-- Row 1 -->
        <div class="row">
          <!-- Discount Code -->
          <div class="flex md4 xs12">
            <va-input
              label="Code Name"
              v-model="discount.discountName"
              placeholder="Type Code Name here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-star"
              />
            </va-input>
          </div>

          <!-- Discount Code -->
          <div class="flex md4 xs12">
            <va-input
              label="Discount Code"
              v-model="discount.discountCode"
              placeholder="Type Code the Customer will use here"
            >
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-tags"
              />
            </va-input>
          </div>

          <!-- Discount Percentage -->
          <div class="flex md4 xs12">
            <va-input
              label="Discount Percentage"
              v-model="discount.discountPercentage"
              placeholder="Type Discount Percentage here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-percent"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">

          <!-- Discount Lead -->
          <div class="flex md12 xs12">
            <va-input
              label="Code lead"
              v-model="discount.lead"
              placeholder="Type Code Lead here">
              <va-icon
                slot="prepend"
                color="primary"
                name="fa fa-star"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 3 -->
        <div class="row">

          <!-- Discount Text Colour -->
          <div class="flex md4 xs12">
            <va-select
              label="Text Colour"
              v-model="discount.colour"
              :options="colourOptions"
            />
          </div>

          <!-- Discount BackgroundColour -->
          <div class="flex md4 xs12">
            <va-select
              label="Background Colour"
              v-model="discount.bgColour"
              :options="colourOptions"
            />
          </div>

          <!-- Discount Highlight Colour -->
          <div class="flex md4 xs12">
            <va-select
              label="Text Highlight"
              v-model="discount.highlight"
              :options="colourOptions"
            />
          </div>
        </div>

        <h3 class="display--3 text--capitalize">Preview</h3>

        <DiscountBanner
          v-bind:discount="discount"
          v-bind:colour="getColourHEX(discount.colour)"
          v-bind:bgColour="getColourHEX(discount.bgColour)"
          v-bind:highlight="getColourHEX(discount.highlight)"
        >
        </DiscountBanner>

        <!-- Save Discount -->
        <div class="row">
          <div class="flex xs6 md9">
          </div>
          <div class="flex xs6 md3">
            <va-button
              medium
              color="info"
              icon="fa fa-bolt"
              type="submit">
              Activate Discount
            </va-button>
          </div>
        </div>
      </va-card>
    </form>
  </div>
</template>

<script>
import DiscountBanner from '@/components_tc/settings/DiscountBanner.vue'

export default {
  name: 'DiscountBar',
  components: {
    DiscountBanner,
  },
  data () {
    return {
      discount: {
        discountCode: '',
        discountName: '',
        discountPercentage: '',
        used: {},

        colour: 'white',
        bgColour: 'blue',
        highlight: 'navy',
        lead: '',
      },
      colourOptions: [
        'black',
        'blue',
        'dark',
        'gold',
        'green',
        'grey',
        'navy',
        'orange',
        'purple',
        'red',
        'yellow',
        'pink',
        'white',
      ],
      colourOptionHexs: {
        black: '03071e',
        orange: 'ffbe0b',
        gold: 'ffea00',
        blue: '3dcae5',
        white: 'ffffff',
        red: 'da2c38',
        green: '40e583',
        dark: '34495e',
        grey: 'efefef',
        yellow: 'ffd500',
        purple: 'b5179e',
        pink: 'ff5d8f',
        navy: '1d3557',
      },
    }
  },

  methods: {
    saveDiscount: function () {
      this.discount.live = true
      this.discount.used = { live: true }
      this.discount.discountPercentage = parseFloat(this.discount.discountPercentage)
      this.discount = this.getColors(this.discount)
      this.$emit('saveDiscount', this.discount)
      this.discount = {}
    },

    getColors: function (ds) {
      ds.colour = this.getColourHEX(ds.colour)
      ds.bgColour = this.getColourHEX(ds.bgColour)
      ds.highlight = this.getColourHEX(ds.highlight)
      return ds
    },

    getColourHEX: function (colour) {
      return '#' + this.colourOptionHexs[colour]
    },
  },
}
</script>
