<template>
  <!-- Discount Spotlight Style -->
  <div
    v-if="discount.lead !== ''"
    class="DiscountBanner mt-4"
    v-bind:style="{color: colour, background: bgColour}">

    <div class="row">
      <div class="flex md12">
        <h6 class="my-1 text-center">

          <!-- Discount Lead -->
          {{ discount.lead.toUpperCase() }} USE DISCOUNT CODE

          <!-- Discount Code -->
          <span v-bind:style="{color: highlight}">
            <strong>'{{ discount.discountCode.toUpperCase() }}'</strong>
          </span>

          TO GET

          <!-- Discount Percentage -->
          <span v-bind:style="{color: highlight}">
            <strong>{{ discount.discountPercentage }}%</strong>
          </span>

          OFF ALL ORDERS
        </h6>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'DiscountBanner',
  props: ['discount', 'colour', 'bgColour', 'highlight'],
}
</script>

<style>
.DiscountBanner {
  width: 100%;
}
</style>
