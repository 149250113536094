<template>
  <div class="discounts">

    <!-- New Discount Form -->
    <DiscountBar
      v-on:saveDiscount="saveDiscount($event)">
    </DiscountBar>

    <!-- Data table headers -->
    <va-card title="All Discounts" class="my-3">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Discount Name or Code"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <!-- Data Table Contents -->
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="discountPercentage" slot-scope="props">
          <p>{{ props.rowData.discountPercentage }}%</p>
        </template>

        <template slot="live" slot-scope="props">
          <va-badge :color="props.rowData.live ? 'success' : 'warning'">
            <p v-if="props.rowData.live">Active</p>
            <p v-else>Paused</p>
          </va-badge>
        </template>

        <template slot="actions" slot-scope="props">
          <va-popover :message="`Spotlight ${props.rowData.discountCode} on site`" placement="left">
            <va-button flat small :color="getActiveColour(props.rowData.discountCode)" icon="fa fa-bolt" v-on:click="spotlightDiscount(props.rowData)"/>
          </va-popover>

          <va-popover :message="`${$t('tables.delete')} Discount ${props.rowData.discountCode}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteDiscount(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { discountCollection } from '@/firebaseConfig.js'

import DiscountBar from '@/components_tc/settings/DiscountBar.vue'

export default {
  name: 'discounts',
  components: {
    DiscountBar,
  },
  data () {
    return {
      term: null,
      perPage: '10',
      loading: false,
      discounts: [],
      spotlightedCode: '',
      perPageOptions: ['10', '25', '50', '100'],
    }
  },
  computed: {
    Discounts: function () {
      return this.$store.state.discounts
    },
    fields () {
      return [{
        name: 'discountName',
        title: this.$t('Discount Name'),
        sortField: 'discountName',
        width: '25%',
      }, {
        name: 'discountCode',
        title: this.$t('Discount Code'),
        sortField: 'discountCode',
        width: '25%',
      }, {
        name: '__slot:discountPercentage',
        title: this.$t('Discount Percentage'),
        sortField: 'discountPercentage',
        width: '20%',
      }, {
        name: '__slot:live',
        title: this.$t('Status'),
        sortField: 'live',
        width: '15%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.discounts
      }

      return this.discounts.filter(item => {
        return item.discountCode.toLowerCase().startsWith(this.term.toLowerCase()) || item.discountName.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    getActiveColour: function (rowCode) {
      return this.spotlightedCode === rowCode ? 'warning' : 'gray'
    },

    saveDiscount: function (discount) {
      const _this = this
      discountCollection
        .doc(discount.discountCode)
        .set(discount)
        .then(() => {
          _this.jobConfirmation('Discount Added')
          _this.discounts.push(discount)
        })
        .catch(err => {
          alert(err.message)
        })
    },

    spotlightDiscount: function (discount) {
      const _this = this
      let newSpotlight = this.buildSpotlight(discount)

      if (this.spotlightedCode === newSpotlight.discountCode) {
        newSpotlight = this.clearSpotlight()
        this.spotlightedCode = ''
      }

      discountCollection
        .doc('spotlight')
        .set(newSpotlight)
        .then(() => {
          _this.spotlightedCode = newSpotlight.discountCode
          _this.jobConfirmation('Spotlight updated')
        })
        .catch(err => {
          alert(err.message)
        })
    },

    buildSpotlight: function (discount) {
      return {
        discountCode: discount.discountCode,
        discountLead: discount.lead,
        discountColour: discount.colour,
        discountBgColour: discount.bgColour,
        discountHighlight: discount.highlight,
        discountPercentage: discount.discountPercentage,
      }
    },

    clearSpotlight: function (discount) {
      return {
        discountCode: '',
        discountLead: '',
        discountColour: '',
        discountBgColour: '',
        discountHighlight: '',
        discountPercentage: '',
      }
    },

    jobConfirmation: function (message) {
      this.showToast(
        message,
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    deleteDiscount: function (discountData, discountIndex) {
      const _this = this
      this.loading = true

      discountCollection
        .doc(discountData.discountCode)
        .delete()
        .then(function () {
          _this.deleteFromTable(discountData, discountIndex)
          _this.showToast(
            'Discount Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (discountData, discountIndex) {
      let realIndex
      const discountIndexRev = (this.discounts.length - 1) - discountIndex

      if (discountData.discountCode === this.discounts[discountIndex].discountCode) {
        realIndex = discountIndex
      } else if (discountData.discountCode === this.discounts[discountIndexRev].discountCode) {
        realIndex = discountIndexRev
      }

      this.discounts.splice(realIndex, 1)
      this.$store.commit('setDiscounts', this.discounts)
      this.loading = false
    },

    getDiscountData: function (event) {
      var _this = this
      this.loading = true

      if (this.Discounts === undefined || this.Discounts.length === 0 || event != null) {
        discountCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonDiscounts = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              if (item.id === 'spotlight') {
                _this.spotlightedCode = itemData.discountCode
              } else {
                jsonDiscounts.push(itemData)
              }
            })

            _this.discounts = jsonDiscounts.sort((a, b) => (a.discountName > b.discountName) ? 1 : -1)
            _this.$store.commit('setDiscounts', _this.discounts)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.discounts = this.Discounts
        this.loading = false
      }
    },
  },

  mounted () {
    this.getDiscountData(null)
  },
}
</script>
